import { Link, generatePath } from 'react-router-dom';
import NumberBadge, { NumberBadgeStatus } from '../NumberBadge';
import { CustomCellRendererProps } from 'ag-grid-react';
import { IAllLoansTableData, QualityFeature } from '../../types';

interface IQualityBadgeCellParams extends CustomCellRendererProps<IAllLoansTableData, QualityFeature> {
  url: string;
}

const QualityBadgeCell = ({ data, value, url }: IQualityBadgeCellParams) => {
  const to = generatePath(url, { mortgageId: data?.internalId ?? '', status: data?.status ?? '' });

  return (
    <Link to={to} style={{ display: 'flex', width: 'fit-content' }}>
      <QualityBadgeNumber value={value} />
    </Link>
  );
};

interface IQualityBadgeNumberProps extends Pick<IQualityBadgeCellParams, 'value'> {
  isRounded?: boolean;
  isCritical?: boolean;
}

const QualityBadgeNumber = ({ value, isRounded, isCritical }: IQualityBadgeNumberProps) => {
  const count = value?.errors ?? 0;
  let status = count === 0 ? NumberBadgeStatus.SUCCESS : NumberBadgeStatus.INFO;

  if (isCritical) {
    status = NumberBadgeStatus.ERROR;
  }

  return (
    <NumberBadge
      value={count}
      isRounded={isRounded}
      isLoading={value?.isLoading}
      isPending={value?.isPending}
      status={status}
    />
  );
};

export { QualityBadgeNumber };
export default QualityBadgeCell;
