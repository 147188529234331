import { useAppSelector } from '../../../hooks';
import { selectQualityMortgages } from '../../mortgage/mortgage.selectors';
import useFetchAllMortgages from '../../mortgage/hooks/useFetchAllMortgages';
import useGetAnalysisDocumentStatusByMortgagesId from '../../analysis-documents/hooks/useGetAnalysisDocumentStatusByMortgagesId';
import { useEffect } from 'react';

const useGetAllDocumentCategorization = () => {
  const { isLoading: isMortgagesLoading } = useFetchAllMortgages();
  const { fetch: fetchDocCategorization, isPending } = useGetAnalysisDocumentStatusByMortgagesId();

  const qualityMortgages = useAppSelector(selectQualityMortgages);

  useEffect(() => {
    const allMortgagesLength = qualityMortgages.draft.length + qualityMortgages.pending.length;

    if (!isMortgagesLoading && allMortgagesLength) {
      const allMortgages = [...qualityMortgages.draft, ...qualityMortgages.pending];
      const ids = allMortgages.map(({ internalId }) => internalId);

      fetchDocCategorization(ids);
    }
  }, [isMortgagesLoading, qualityMortgages, fetchDocCategorization]);

  return { isPending };
};

export default useGetAllDocumentCategorization;
