import { AxiosResponse } from 'axios';
import { injectable } from 'inversify';
import 'reflect-metadata';
import { client } from 'shared/api/clients/client';
import { IBidTapeRepository } from 'shared/api/interfaces/IBidTapeRepository';
import { BaseRepository } from 'shared/api/repositories/BaseRepository';
import { IBidTape } from 'shared/models/BidTape';

@injectable()
export class BidTapeRepository implements IBidTapeRepository {
  private baseRepository = new BaseRepository();

  apiUrl: string = this.baseRepository.getApiUrl('originator/v2/bid-tape');

  update = async (id: string, item: IBidTape): Promise<AxiosResponse<any, any>> => {
    return await client.put(this.apiUrl, item);
  };

  delete = async (id: string): Promise<AxiosResponse> => {
    return await client.delete(`${this.apiUrl}/${id}`);
  };

  getAll = async (): Promise<AxiosResponse<IBidTape[]>> => {
    return await client.get(this.apiUrl);
  };

  add = async (bidtape: any): Promise<AxiosResponse> => {
    return await client.post(this.apiUrl, bidtape);
  };

  isDuplicate = async (name: string): Promise<AxiosResponse> => {
    return await client.get(`${this.apiUrl}/is-duplicate/${name}`);
  };
}
