import { useMemo } from 'react';
import { IBidWithNestedBids, IBidWithPayup } from '../../../../models/Bid';
import { ICellRendererParams } from 'ag-grid-community';
import { IconMapper, PayupNames, PayupNamesByCompanyName } from 'shared/utils/specPoolIconMapper.helper';
import { useAppSelector } from '../../../../../state-management/hooks';
import { selectConfig } from '../../../../../state-management/slices/config/config.selectors';
import './PayupIcons.scss';
import { TCompanyConfig } from '../../../../../state-management/slices/config/config.slice';

interface IPayupIconCellRendererProps extends ICellRendererParams<IBidWithNestedBids | IBidWithPayup> {
  value: string;
}

export const PayupIconCellRenderer = ({ value }: IPayupIconCellRendererProps) => {
  const config = useAppSelector(selectConfig);

  const iconsToShow = useMemo(() => {
    let company: TCompanyConfig;

    // value is a tuple firm + payup
    return value
      .split('|')
      .map((payupName, index) => {
        if (index === 0) {
          company = config.companies[payupName];

          if (company) {
            const payupIconKey = PayupNamesByCompanyName[company.name];

            if (!payupIconKey) {
              console.error(`Payup name not found for ${payupName}`);
              return '';
            }

            // e.g. PayupNames['PENNY_MAC'] => 'Penny Mac'
            return PayupNames[payupIconKey as keyof typeof PayupNames];
          }
        }

        if (lowLoanBalanceStrings.has(payupName)) {
          return PayupNames.LOW_LOAN;
        }

        return payupName;
      })
      .filter(Boolean);
  }, [value, config]);

  if (!value) {
    return null;
  }

  return (
    <div className="ml-0 flex h-5 w-full items-end justify-start">
      {iconsToShow.map((name, index) => (
        <PayupIcon key={name} iconName={name} index={index} />
      ))}
      {iconsToShow.length > MAX_ICONS_TO_SHOW && (
        <span role="contentinfo" className="self-center pl-2 text-sm font-semibold text-slate-500">
          +{iconsToShow.length - MAX_ICONS_TO_SHOW}
        </span>
      )}
    </div>
  );
};

export const lowLoanBalanceStrings = new Set([
  // Freddie
  'Max LLB <= 85,000',
  'Max LLB <= 110,000',
  'Max LLB <= 125,000',
  'Max LLB <= 150,000',
  'Max LLB <= 175,000',
  'Max LLB <= 200,000',
  'Max LLB <= 225,000',
  'Max LLB <= 250,000',
  'Max LLB <= 275,000',
  // Fannie
  '85k Max Loan Amount',
  '110k Max Loan Amount',
  '125k Max Loan Amount',
  '150k Max Loan Amount',
  '175k Max Loan Amount',
  '200k Max Loan Amount',
  '225k Max Loan Amount',
  '250k Max Loan Amount',
  '275k Max Loan Amount'
]);

const MAIN_ICON_SIZE = 20;
const SECONDARY_ICON_SIZE = MAIN_ICON_SIZE - 6;
const MAX_ICONS_TO_SHOW = 3;

interface PayupIconProps {
  iconName: string;
  index: number;
}

const PayupIcon = ({ iconName, index }: PayupIconProps) => {
  // Display only 3 icons.
  if (index >= MAX_ICONS_TO_SHOW || !IconMapper[iconName]) {
    return null;
  }
  const { Icon, hasCircularShape } = IconMapper[iconName];
  const isFirstIcon = index === 0;
  /* Icon styles */
  const iconBorder = !isFirstIcon ? 'overlap-border' : '';
  const classByShape = hasCircularShape ? 'rounded-full w-6' : 'rounded-sm';

  // Handling icons sizing
  const iconSize = isFirstIcon ? MAIN_ICON_SIZE : SECONDARY_ICON_SIZE;
  // Margin to overlap the icon.
  const marginLeft = !isFirstIcon ? -6 : 0;

  return (
    <div
      key={iconName}
      style={{ zIndex: index, marginLeft }}
      className={`payup-icon flex items-center justify-start ${iconBorder} ${classByShape}`}
    >
      <Icon width={iconSize} height={iconSize} />
    </div>
  );
};
