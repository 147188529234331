import moment from 'moment';

export const shortenStrWithEllipsis = (str: string, threshold = 15) => {
  if (!str) return '';
  if (str.length <= threshold) return str;
  const start = str.substring(0, Math.floor(threshold / 2));
  const end = str.substring(str.length - Math.floor(threshold / 3) + 1);

  return `${start}...${end}`;
};

export const formatDeliveryDateLong = (deliveryDate: string): string => (deliveryDate === '' ? '' : moment(deliveryDate).format('MMMM D, YYYY'));
