import { CreateSliceOptions } from '@reduxjs/toolkit';
import Big from 'big.js';
import { arrayReducer as arrayReducers } from '../utils/array.slice';
import { BidHistoryStatusSorted, BidType, IBid, IBidWithPayup } from 'shared/models/Bid';
import { IMortgage, IMortgageFlat, IMortgageMappable } from 'shared/models/Mortgage';
import { IPayup } from 'shared/models/Payup';
import { shortNameDateTimeFormat } from 'shared/utils/formatting';
import { getFinalPrice, getPayupAmountFromServicerFee } from '../../shared/utils/bid.helper';
import moment from 'moment';

// temporary any
export interface MergedReducers {
  name: string;
  reducer: { reducer: any; prepare: any };
}

export const buildGenericSlice = <T>(
  name: string,
  initialState: T,
  type = 'List',
  mergedReducers?: MergedReducers[]
): CreateSliceOptions => {
  const arrayFunctions = arrayReducers(name, type, mergedReducers);

  return {
    name: name,
    initialState,
    reducers: {
      ...arrayFunctions
    }
  };
};

export const merge = (arr1: IMortgageFlat[], arr2: IMortgageFlat[]): IMortgageFlat[] => {
  const newArr: IMortgageFlat[] = [...arr1];

  for (let i = 0; i < arr2.length; i++) {
    const item = arr2[i];

    if (newArr.includes(item)) continue;
    newArr.push(item);
  }
  return newArr;
};

export const flattenMortgage = (mortgage: IMortgage): IMortgageFlat => ({
  internalId: mortgage.internalId,
  loanNumber: mortgage.loanNumber,
  universalLoanId: mortgage.universalLoanId,
  mersMIN: mortgage.mersMIN,
  type: mortgage.loanDetails.governmentType,
  status: mortgage.status,
  ficoScore: mortgage.loanDetails.creditScore,
  ltv: mortgage.loanDetails.loanToValue,
  cltv: mortgage.loanDetails.combinedLoanToValue,
  noteAmount: mortgage.loanDetails.noteAmount,
  unpaidPrincipleBalance: mortgage.loanDetails.unpaidPrincipleBalance,
  totalAmount: mortgage.loanDetails.totalAmount,
  location: `${mortgage.propertyDetails.city}, ${mortgage.propertyDetails.state}`,
  zip: Number(mortgage.propertyDetails.zipCode),
  currentInterestRate: mortgage.loanDetails.currentInterestRate,
  originalTerm: mortgage.loanDetails.originalTerm,
  currentTerm: mortgage.loanDetails.currentTerm,
  automatedUnderwritingSystemType: mortgage.loanDetails.automatedUnderwritingSystemType,
  loanDocument: mortgage.loanDetails.loanDocument,
  escrowWaived: mortgage.loanDetails.escrowWaived,
  address: mortgage.propertyDetails.address,
  city: mortgage.propertyDetails.city,
  state: mortgage.propertyDetails.state,
  purpose: mortgage.loanDetails.purpose,
  propertyType: mortgage.propertyDetails.propertyType,
  occupancy: mortgage.propertyDetails.occupancy,
  livingUnits: mortgage.propertyDetails.livingUnits,
  appraisedValue: mortgage.propertyDetails.appraisedValue,
  salesPrice: mortgage.propertyDetails.salesPrice,
  borrowerIncome: mortgage.loanDetails.totalBorrowerIncome,
  debtToIncome: mortgage.loanDetails.debtToIncomeRatio,
  loanProgram: mortgage.loanDetails.loanProgram,
  winningBidAmount: mortgage.winningBidAmount,
  plannedClosingDate: mortgage.loanDetails.plannedClosingDate,
  soldDate: mortgage.soldDate,
  openDate: mortgage.openDate,
  closedDate: mortgage.closedDate,
  createdDate: mortgage.createdDate,
  numInvestors: mortgage.numInvestors,
  soldTo: mortgage.soldTo?.split('::')[0] || '',
  purchasedFrom: mortgage.purchasedFrom?.split('::')[0] || '',
  bidSubmitter: mortgage.bidSubmitter ? mortgage.bidSubmitter : '',
  bidApprover: mortgage.bidApprover ? mortgage.bidApprover : '',
  termYears: mortgage.extensionData.termYears,
  amortizationType: mortgage.extensionData.amortizationType,
  initialFixedPeriodEffectiveMonthsCount: mortgage.extensionData.initialFixedPeriodEffectiveMonthsCount,
  loanProgramIdentifier: mortgage.extensionData.loanProgramIdentifier,
  investorContractId: mortgage.investorContractId,
  rateToPrice: mortgage.rateToPrice,
  documentCustodianId: mortgage.extensionData.documentCustodianId,
  payeeId: mortgage.extensionData.payeeId,
  warehouseLenderId: mortgage.extensionData.warehouseLenderId,
  investorCommitmentId: mortgage.extensionData.investorCommitmentId
});

export const flattenMortgages = (mortgages: IMortgage[]): IMortgageFlat[] => {
  return (
    // Sort by loanNumber for consistency, we could use internalId, but it's alphanumeric so not a very reliable sorting method
    mortgages.map(flattenMortgage).sort((a, b) => a.loanNumber.localeCompare(b.loanNumber))
  );
};

export const unflattenMortgage = (mortgage: IMortgageFlat): IMortgageMappable => ({
  internalId: mortgage.internalId,
  universalLoanId: mortgage.universalLoanId,
  mersMIN: mortgage.mersMIN,
  loanDetails: {
    loanProgram: mortgage.loanProgram,
    automatedUnderwritingSystemType: mortgage.automatedUnderwritingSystemType,
    governmentType: mortgage.type,
    originalTerm: mortgage.originalTerm,
    currentInterestRate: mortgage.currentInterestRate,
    currentTerm: mortgage.currentTerm,
    noteAmount: mortgage.noteAmount,
    unpaidPrincipleBalance: mortgage.unpaidPrincipleBalance,
    totalAmount: mortgage.totalAmount,
    purpose: mortgage.purpose,
    loanToValue: mortgage.ltv,
    combinedLoanToValue: mortgage.cltv,
    debtToIncomeRatio: mortgage.debtToIncome,
    loanDocument: mortgage.loanDocument,
    escrowWaived: mortgage.escrowWaived,
    ficoScore: mortgage.ficoScore,
    borrowerIncome: mortgage.borrowerIncome,
    loanProgramIdentifier: mortgage.loanProgramIdentifier
  },
  loanNumber: mortgage.loanNumber,
  propertyDetails: {
    address: mortgage.address,
    city: mortgage.city,
    state: mortgage.state,
    zip: mortgage.zip?.toString(),
    propertyType: mortgage.propertyType,
    occupancy: mortgage.occupancy,
    appraisedValue: mortgage.appraisedValue,
    salesPrice: mortgage.salesPrice,
    livingUnits: mortgage.livingUnits
  },
  extensionData: {
    termYears: mortgage.termYears,
    amortizationType: mortgage.amortizationType,
    initialFixedPeriodEffectiveMonthsCount: mortgage.initialFixedPeriodEffectiveMonthsCount,
    loanProgramIdentifier: mortgage.loanProgramIdentifier,
    documentCustodianId: mortgage.documentCustodianId,
    payeeId: mortgage.payeeId,
    warehouseLenderId: mortgage.warehouseLenderId,
    investorCommitmentId: mortgage.investorCommitmentId
  },
  winningBidAmount: mortgage.winningBidAmount,
  soldDate: mortgage.soldDate,
  createdDate: mortgage.createdDate,
  closedDate: mortgage.closedDate,
  openDate: mortgage.openDate,
  soldTo: mortgage.soldTo,
  purchasedFrom: mortgage.purchasedFrom,
  numInvestors: mortgage.numInvestors,
  investorContractId: mortgage.investorContractId,
  rateToPrice: mortgage.rateToPrice
});

const parseIntValue = (value: number | string): number => {
  if (typeof value === 'string') {
    return parseInt(value);
  } else {
    return value;
  }
};

export const filterValuesBidTape = (bidtapeParameters: any) => {
  return {
    ...bidtapeParameters,
    ficoScoreMin: !bidtapeParameters.ficoScoreMin ? 0 : parseIntValue(bidtapeParameters.ficoScoreMin),
    ficoScoreMax: !bidtapeParameters.ficoScoreMax ? 850 : parseIntValue(bidtapeParameters.ficoScoreMax),
    rateMin: !bidtapeParameters.rateMin ? 0 : parseFloat(bidtapeParameters.rateMin),
    rateMax: !bidtapeParameters.rateMax ? 25 : parseFloat(bidtapeParameters.rateMax),
    termMin: !bidtapeParameters.termMin ? 0 : parseIntValue(bidtapeParameters.termMin),
    termMax: !bidtapeParameters.termMax ? 360 : parseIntValue(bidtapeParameters.termMax)
  };
};

export const getFirmFromAgent = (agent: IBid['agent']): string => agent.substring(0, agent.indexOf('::'));

const _joinFlattenBid = (bid: IBid) => {
  const latestBidStatus = bid.bidHistory[0].status;

  return {
    firm: getFirmFromAgent(bid.agent),
    bidType: BidType.WholeLoan, // TODO: When other bids are implements pass in id and map to bid types
    deliveryDate: formatDeliveryDate(bid?.deliveryDate),
    receivedDate: shortNameDateTimeFormat(bid?.receivedDate || ''),
    approvedDate: shortNameDateTimeFormat(bid?.approvedDate || ''),
    buyerId: bid.agent,
    sellerId: bid.owner,
    status: latestBidStatus,
    readOnlyServicer: BidHistoryStatusSorted[latestBidStatus] > BidHistoryStatusSorted.BID_SUBMITTED,
    outby: null
  };
};

export const joinBidWithPayup = (
  bid: IBid,
  payup: IPayup,
  selectedPayup?: IPayup,
  servicingRate: IBid['pricingOption'] = '0.25'
): IBidWithPayup => {
  const finalPrice = getFinalPrice({ ...bid, servicingRate }, selectedPayup, payup);
  // We don't need payup prices in the table as we're showing the final price
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { amountForRate025, amountForRate0375, amountForRate05, ...payupWOPrices } = payup;

  return {
    ...bid,
    ...payupWOPrices,
    ..._joinFlattenBid(bid),
    finalPrice,
    payupName: payup.name,
    payupAmount: Big(getPayupAmountFromServicerFee(servicingRate, payup)).toFixed(3),
    isAccepted: bid.isAccepted && bid.payup === payup.name
  };
};

export const flattenBidNoPayup = (
  bid: IBid,
  selectedPayup?: IPayup,
  servicingRate?: IBid['pricingOption']
): IBidWithPayup => {
  const finalPrice = getFinalPrice({ ...bid, servicingRate }, selectedPayup);

  return {
    ...bid,
    ..._joinFlattenBid(bid),
    finalPrice,
    payupName: '',
    payupAmount: '0',
    isAccepted: bid.isAccepted && !bid.payup
  };
};

export const mapPayupsToMortgageId = (payups: IPayup[]): Record<string, IPayup[]> => {
  const payupMap: Record<string, IPayup[]> = {};

  payups.forEach((payup: IPayup) => {
    if (payupMap[payup.internalMortgageId]) {
      payupMap[payup.internalMortgageId].push(payup);
    } else {
      payupMap[payup.internalMortgageId] = [payup];
    }
  });

  return payupMap;
};

// on OtherBidPrice pass the highest bid plus payup if the currentBidPrice is not the highest one, if it is the max, pass on the other price, the second highest one
export const calculateOutby = (currentBidPrice: string, otherBidPrice: string): string => {
  const currentPrice = new Big(currentBidPrice);
  const otherPrice = new Big(otherBidPrice);

  const outby = currentPrice.minus(otherPrice);

  return outby.toString(); //TODO change to a fixed amount of decimals
};

export const formatDeliveryDate = (deliveryDate: string): string => moment(deliveryDate).format('MM-DD');
