import { createSelector } from '@reduxjs/toolkit';
import sortBy from 'lodash/sortBy';
import { selectQualityMortgages } from '../mortgage/mortgage.selectors';
import { selectDocumentStatus, selectLQAValidations } from '../analysis-documents/selectors';
import { IAllLoansTableData } from '../../../Sections/Originator/Pages/Quality/types';
import { getBidMap } from '../bid/bid.utils';
import { selectBids } from '../bid/bid.selectors';
import { DocumentStatus, IDocumentStatus } from '../../../shared/models/Analysis-Document';
import { IMortgage, IMortgageFlat } from 'shared/models/Mortgage';
import { NormalizedLQAValidation, NormalizedLQAValidationStatus } from './types';

const mapMortgagesForTable = (
  mortgages: IMortgageFlat[],
  documentStatus: Record<string, IDocumentStatus>,
  lqaStatus: Record<IMortgage['internalId'], NormalizedLQAValidation>
) =>
  mortgages.map((mortgage) => {
    const isLoading = !documentStatus;
    const isPending = !isLoading && !documentStatus[mortgage.internalId]; // API does not return results if there are no documents
    const rulesTodo =
      documentStatus[mortgage.internalId]?.rulesFailing + documentStatus[mortgage.internalId]?.rulesAutoPass;

    return {
      ...mortgage,
      closeChecklist: {
        isLoading,
        isPending: isPending || documentStatus[mortgage.internalId]?.status === DocumentStatus.UNSTARTED,
        errors: rulesTodo
      },
      documentCategorization: {
        isPending,
        isLoading,
        errors: documentStatus[mortgage.internalId]?.docsUncategorized ?? 0
      },
      loanQualityAdvisor: {
        isPending: lqaStatus[mortgage.internalId]?.status !== NormalizedLQAValidationStatus.COMPLETE,
        isLoading: !lqaStatus[mortgage.internalId],
        errors: lqaStatus[mortgage.internalId]?.errorsCount ?? 0
      }
    };
  });

export const selectAllDraftTableMortgages = createSelector(
  selectQualityMortgages,
  selectDocumentStatus,
  selectLQAValidations,
  ({ draft }, documentStatus, lqaValidations): IAllLoansTableData[] => {
    // Sort by planned closing date
    const sortedMortgages = sortBy(draft, ['plannedClosingDate']);

    return mapMortgagesForTable(sortedMortgages, documentStatus, lqaValidations);
  }
);

export const selectAllPendingTableMortgages = createSelector(
  selectQualityMortgages,
  selectBids,
  selectDocumentStatus,
  selectLQAValidations,
  ({ pending }, bids, documentStatus, lqaValidations): IAllLoansTableData[] => {
    // Add delivery date
    let deliveryDate;
    const bidsMap = getBidMap(pending, bids);
    const mortgagesWithDeliveryDate = pending.map((mortgage) => {
      deliveryDate = bidsMap[mortgage.internalId]?.[0]?.deliveryDate ?? null;

      // bids may not be loaded yet
      if (bidsMap[mortgage.internalId]?.[0] && !deliveryDate) {
        console.error('No delivery date for mortgage', mortgage.internalId, mortgage.loanNumber);
      }

      return { ...mortgage, deliveryDate };
    });

    // Sort by delivery date first,
    const sortedMortgages = sortBy(mortgagesWithDeliveryDate, ['deliveryDate']);

    return mapMortgagesForTable(sortedMortgages, documentStatus, lqaValidations);
  }
);

// One off selector to get all mortgages
export const selectAllTableMortgages = createSelector(
  selectAllDraftTableMortgages,
  selectAllPendingTableMortgages,
  (draft, pending): IAllLoansTableData[] => {
    return [...draft, ...pending];
  }
);
