import { useEffect, useMemo, useState } from 'react';
import { TreeInformation, TreeItem, TreeItemIndex, TreeItemRenderContext } from 'react-complex-tree';
import 'react-complex-tree/lib/style-modern.css';
import { useAppSelector } from '../../../../../../../state-management/hooks';
import {
  makeSelectPercentageCompleteByMortgageId,
  makeSelectPreviewDocumentsByMortgageId
} from '../../../../../../../state-management/slices/analysis-documents/selectors';
import { generatePath, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { selectAnalysisCategoryMap } from '../../../../../../../state-management/slices/analysis-categories/selectors';
import { DocumentReviewParams, ItemData } from '../../types';
import { CheckSvg, ChevronUp, Folders, ChevronDown } from 'shared/images';
import ItemTitle from '../../components/Sidebar/ItemTitle';
import partition from 'lodash/partition';
import { shortenStrWithEllipsis } from '../../utils';
import classNames from 'classnames';
import { _getCategorySidebarItems, _getDocumentSidebarItems } from './utils';
import { RouteUtils } from 'Sections/Originator/Pages/Quality/RouteUtils';

type TRenderItemArrow = {
  item: TreeItem<ItemData>;
  context: TreeItemRenderContext<'expandedItems' | 'focusedItem' | 'selectedItems'>;
  info: TreeInformation;
};

const ItemArrow = ({ item }: TRenderItemArrow) => (item.isFolder ? <Folders className="icon" /> : null);

export const UNCATEGORIZED_TITLE = 'uncategorized';
export const CATEGORIZED_TITLE = 'categorized';
export const VIEW_ALL_CATEGORY_ID = 'view-all';

// fetch data for nav and parse into tree structure
const useSidebarNav = () => {
  const { mortgageId = '', status, documentId = '', category = '' } = useParams<DocumentReviewParams>();
  const navigate = useNavigate();
  const [queryParams] = useSearchParams();

  const selectPreviewDocumentsByMortgageId = useMemo(makeSelectPreviewDocumentsByMortgageId, []);
  const documentsList = useAppSelector((state) => selectPreviewDocumentsByMortgageId(state, mortgageId));
  const selectPercentageCompleteByMortgageId = useMemo(makeSelectPercentageCompleteByMortgageId, []);
  const progressComplete = useAppSelector((state) => selectPercentageCompleteByMortgageId(state, mortgageId));
  const categoryMap = useAppSelector(selectAnalysisCategoryMap);

  const [uncategorizedDocuments, categorizedDocuments] = useMemo(
    () => partition(documentsList ?? [], (doc) => !doc?.category),
    [documentsList]
  );

  const [navItems, setNavItems] = useState({});
  const [focusedItem, setFocusedItem] = useState<TreeItem['index']>();
  const [expandedItems, setExpandedItems] = useState<TreeItem['index'][]>([CATEGORIZED_TITLE, UNCATEGORIZED_TITLE]);
  const [selectedDocs, setSelectedDocs] = useState<TreeItem['index'][]>([]);
  const [selectedCategory, setSelectedCategory] = useState<TreeItem['index'][]>([]);

  useEffect(() => {
    if (!documentId) return;
    // get document selected using documentId from url params
    const selectedDocument = documentsList.find((doc) => doc.id === documentId);

    setSelectedDocs([selectedDocument?.fileName ?? '']);
  }, [documentId, documentsList]);

  useEffect(() => {
    if (!category) return;
    // get category selected using categoryId from url params
    setSelectedCategory([category]);
  }, [category]);

  // add categories to navItems state
  useEffect(() => {
    setNavItems((prev) => {
      const updatedCategories = _getCategorySidebarItems(categorizedDocuments, categoryMap);

      return {
        ...prev,
        ...updatedCategories
      };
    });
  }, [categorizedDocuments, categoryMap]);

  // add documents to navItems state
  useEffect(() => {
    setNavItems((prev) => {
      const updatedDocuments = _getDocumentSidebarItems(uncategorizedDocuments);

      return {
        ...prev,
        ...updatedDocuments
      };
    });
  }, [uncategorizedDocuments]);

  const viewState = useMemo(
    () => ({
      [CATEGORIZED_TITLE]: {
        expandedItems: ['root2', ...expandedItems],
        focusedItem,
        selectedItems: selectedCategory
      },
      [UNCATEGORIZED_TITLE]: {
        expandedItems: ['root', ...expandedItems],
        focusedItem,
        selectedItems: selectedDocs
      }
    }),
    [expandedItems, focusedItem, selectedDocs, selectedCategory]
  );

  const handleOnExpand = (item: TreeItem<ItemData>) => {
    setExpandedItems([...expandedItems, item.index]);
  };

  const handleOnCollapse = (item: TreeItem<ItemData>) => {
    setExpandedItems(expandedItems.filter((expandedItemIndex) => expandedItemIndex !== item.index));
  };

  const handleOnFocus = (item: TreeItem<ItemData>) => setFocusedItem(item.index);

  const getItemTitle = (item: TreeItem<ItemData>) => item.data.displayName;

  const handleSelectItem = (items: TreeItemIndex[]) => {
    if (documentId) {
      setSelectedDocs(items);
    } else if (category) {
      setSelectedCategory(items);
    }
  };

  const handlePrimaryAction = (item: TreeItem<any>): void => {
    const _category = item.data.category;
    const _documentId = item.data.documentId;

    const url = _category ? RouteUtils.documentCategorizationCategoryUrl : RouteUtils.documentCategorizationReviewUrl;
    const pathname = generatePath(url, { mortgageId, status, documentId: _documentId, category: _category });

    navigate({ pathname, search: queryParams.toString() });
  };

  const ItemTitleWithDocCount = ({ item }: { title: string; item: TreeItem<ItemData> }) => {
    if (!item) return null;
    const children = item?.children;
    const isCategorized = item.index === CATEGORIZED_TITLE;
    const isAllDocumentsReviewed = progressComplete === 100;
    const displayName = item.data.displayName;
    const isExpanded = expandedItems.includes(item.index) && item.isFolder;

    let text = !children ? shortenStrWithEllipsis(displayName, 25) : displayName;
    let count = isCategorized ? categorizedDocuments.length : children?.length;
    let icon = isExpanded ? <ChevronDown width={20} height={20} /> : <ChevronUp width={20} height={20} />;

    const className = classNames({
      'count-pill categorized': isCategorized,
      'count-pill': !isCategorized,
      'count-pill completed': !isCategorized && children?.length === 0 && isAllDocumentsReviewed
    });

    if (!isCategorized && isAllDocumentsReviewed && children?.length === 0) {
      count = 0;
      icon = <CheckSvg width={15} height={15} />;
      text = displayName;

      return (
        <ItemTitle
          title={text}
          tooltipText={displayName}
          isFolder={item.isFolder}
          countComponent={
            <span className={className}>
              {icon} {count}
            </span>
          }
        />
      );
    }

    return (
      <ItemTitle
        title={text}
        tooltipText={displayName}
        isFolder={item.isFolder}
        countComponent={
          !children ? null : (
            <>
              <span className={className}>{count}</span>
              {icon}
            </>
          )
        }
      />
    );
  };

  return {
    navItems,
    viewState,
    handleOnExpand,
    handleOnCollapse,
    handleOnFocus,
    handleSelectItem,
    getItemTitle,
    ItemArrow,
    ItemTitleWithDocCount,
    handlePrimaryAction
  };
};

export default useSidebarNav;
