import { Container } from 'inversify';
import { SERVICE_KEYS } from 'shared/api//keys.const';
import { IUserRepository } from 'shared/api/interfaces/IUserRepository';
import { UserRepository } from 'shared/api/repositories/UserRepository/UserRepository';
import { AuthRepository } from 'shared/api/repositories/AuthRepository/AuthRepository';
import { IAuthRepository } from 'shared/api/interfaces/IAuthRepository';
import { IMortgageRepository } from './interfaces/IMortgageRepository';
import { MortgageRepository } from './repositories/MortgageRepository/MortgageRepository';
import { IBidTapeRepository } from './interfaces/IBidTapeRepository';
import { IInvestorRepository } from './interfaces/IInvestorRepository';
import { InvestorRepository } from './repositories/InvestorRepository/InvestorRepository';
import { BidTapeRepository } from './repositories/BidTapeRepository/BidTapeRepository';
import { IBidRepository } from './interfaces/IBidRepository';
import { BidRepository } from './repositories/BidRepository/BidRepository';
import { TraderRepository } from './repositories/TraderRepository/TraderRepository';
import { ITraderRepository } from './interfaces/ITraderRepository';
import { IMortgageDocumentRepository } from './interfaces/IMortgageDocumentRepository';
import { MortgageDocumentRepository } from './repositories/MortgageDocumentRepository/MortgageDocumentRepository';
import { IPayupRepository } from './interfaces/IPayupRepository';
import { PayupRepository } from './repositories/PayupRepository/PayupRepository';
import { IDeliveryDateRepository } from 'shared/api/interfaces/IDeliveryDateRepository';
import { DeliveryDateRepository } from 'shared/api/repositories/DeliveryDateRepository/DeliveryDateRepository';
import { IPricingOptionRepository } from './interfaces/IPricingOptionRepository';
import { PricingOptionRepository } from './repositories/PricingOptionRepository/PricingOptionRepository';
import { IBulkRepository } from './interfaces/IBulkRepository';
import { BulkRepository } from './repositories/BulkRepository/BulkRepository';

const _container = new Container();

_container.bind<IUserRepository>(SERVICE_KEYS.USER_REPOSITORY).to(UserRepository).inSingletonScope();
_container.bind<IAuthRepository>(SERVICE_KEYS.AUTH_REPOSITORY).to(AuthRepository).inSingletonScope();
_container.bind<IBulkRepository>(SERVICE_KEYS.BULK_REPOSITORY).to(BulkRepository).inSingletonScope();
_container.bind<IMortgageRepository>(SERVICE_KEYS.MORTGAGE_REPOSITORY).to(MortgageRepository).inSingletonScope();
_container.bind<IInvestorRepository>(SERVICE_KEYS.INVESTOR_REPOSITORY).to(InvestorRepository).inSingletonScope();
_container.bind<IBidTapeRepository>(SERVICE_KEYS.BIDTAPE_REPOSITORY).to(BidTapeRepository).inSingletonScope();
_container.bind<IBidRepository>(SERVICE_KEYS.BID_REPOSITORY).to(BidRepository).inSingletonScope();
_container.bind<ITraderRepository>(SERVICE_KEYS.TRADER_REPOSITORY).to(TraderRepository).inSingletonScope();
_container.bind<IMortgageDocumentRepository>(SERVICE_KEYS.MORTGAGE_DOCUMENT_REPOSITORY).to(MortgageDocumentRepository).inSingletonScope();
_container.bind<IPayupRepository>(SERVICE_KEYS.PAYUP_REPOSITORY).to(PayupRepository).inSingletonScope();
_container.bind<IDeliveryDateRepository>(SERVICE_KEYS.DELIVERY_DATE_REPOSITORY).to(DeliveryDateRepository).inSingletonScope();
_container.bind<IPricingOptionRepository>(SERVICE_KEYS.PRICING_OPTION_REPOSITORY).to(PricingOptionRepository).inSingletonScope();

const container = _container;

export { container };
