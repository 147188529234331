import { createSelector } from 'reselect';
import { IAnalysisCategory } from 'shared/models/Analysis-Categories';
import { RootState } from '../../store';

export const selectAnalysisCategories = (state: RootState) => state.analysisCategories.documentCategories;
export const selectAnalysisCategoryMap = createSelector(selectAnalysisCategories, (categories) =>
  categories.reduce((acc, category) => {
    acc[category.name] = category.name;
    return acc;
  }, {} as Record<IAnalysisCategory['name'], string>)
);
