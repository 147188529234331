import { useEffect } from 'react';
import { useAppDispatch } from '../../../hooks';
import { setDraftMortgages, setPendingMortgages } from '../mortgage.slice';
import { IMortgage } from '../../../../shared/models/Mortgage';
import { useQueries } from '@tanstack/react-query';
import { MortgageRepository } from '../../../../shared/api/repositories/MortgageRepository/MortgageRepository';
import { AxiosResponse } from 'axios';

const mortgageRepository = new MortgageRepository();

const useFetchAllMortgages = () => {
  const dispatch = useAppDispatch();

  // TODO: Replace by fetch all mortgages HD-2618
  const [{ data: pendingMortgages, isPending: isPendingLoading }, { data: draftMortgages, isPending: isDraftLoading }] =
    useQueries({
      queries: [
        {
          staleTime: 60 * 1000, // 1 minute
          enabled: true,
          queryKey: ['quality', 'mortgages', 'pending'],
          queryFn: () => mortgageRepository.getPendingMortgages(),
          select: (response: AxiosResponse<IMortgage[]>) => response.data
        },
        {
          staleTime: 60 * 1000, // 1 minute
          enabled: true,
          queryKey: ['quality', 'mortgages', 'draft'],
          queryFn: () => mortgageRepository.getDraftMortgages(),
          select: (response: AxiosResponse<IMortgage[]>) => response.data
        }
      ]
    });

  useEffect(() => {
    if (draftMortgages) {
      dispatch(setDraftMortgages({ data: draftMortgages }));
    }
  }, [draftMortgages, dispatch]);

  useEffect(() => {
    if (pendingMortgages) {
      dispatch(setPendingMortgages({ data: pendingMortgages }));
    }
  }, [pendingMortgages, dispatch]);

  return {
    isLoading: isDraftLoading || isPendingLoading
  };
};

export default useFetchAllMortgages;
