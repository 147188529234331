import { useQuery } from '@tanstack/react-query';
import { DocumentQualityBaseRepository } from 'shared/api/repositories/DocumentQualityBaseRepository';
import { client } from 'shared/api/clients/client';
import { IMortgage } from 'shared/models/Mortgage';
import { IAnalysisDocumentPreview } from '../../../../shared/models/Analysis-Document';
import { AxiosResponse } from 'axios';

const _repo = new DocumentQualityBaseRepository();

// TODO fix response typed as any
const useLoadPreviewDocumentsQuery = (mortgageId: IMortgage['internalId']) => {
  return useQuery({
    staleTime: 300,
    enabled: Boolean(mortgageId),
    queryKey: ['mortgagePreviewDocuments', mortgageId],
    queryFn: ({ queryKey }): Promise<AxiosResponse<IAnalysisDocumentPreview[]>> =>
      client.get(_repo.getApiUrl(`/${queryKey[1]}/documents`)),
    select: (response) => response.data
  });
};

export default useLoadPreviewDocumentsQuery;
