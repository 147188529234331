import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface QualityControlSlice {
  searchValue: string;
}

const QualityControlInitialState: QualityControlSlice = {
  searchValue: ''
};
const qualityControlSlice = createSlice({
  name: 'qualityControl',
  initialState: QualityControlInitialState,
  reducers: {
    setSearchValue: (state, action: PayloadAction<QualityControlSlice['searchValue']>) => {
      state.searchValue = action.payload;
    }
  }
});

export const { setSearchValue } = qualityControlSlice.actions;
export default qualityControlSlice.reducer;
