import mixpanel, { track } from 'mixpanel-browser';

export enum mixpanelEventNames {
  DOC_CAT_APPROVE_DOCUMENT_CATEGORY = 'DOC_CAT_APPROVE_DOCUMENT_CATEGORY',
  CHECKLIST_REPORT_RULE_ERROR = 'CHECKLIST_REPORT_RULE_ERROR',
  CHECKLIST_APPROVE_RULE = 'CHECKLIST_APPROVE_RULE',
  CHECKLIST_DOCUMENT_CATEGORIZATION_ERROR = 'CHECKLIST_DOCUMENT_CATEGORIZATION_ERROR'
}
export enum mixpanelPageNames {
  DOC_CAT = 'Document Review',
  CHECKLIST = 'Checklist'
}

// mixpanel will throw an error if token is not set
// https://github.com/mixpanel/mixpanel-js/issues/133#issuecomment-417573211
export const isMixpanelLoaded = () => {
  let result;

  try {
    const mixpanelDistinctId = mixpanel.get_distinct_id();

    result = mixpanelDistinctId ? mixpanel : null;
  } catch (e) {
    result = null;
  }

  return result;
};

// params of track func
type TMixpanelTrackParams = Parameters<typeof track>;

export const mixpanelTrack = (...trackParams: TMixpanelTrackParams) => {
  isMixpanelLoaded()?.track(...trackParams);
};

export const mixpanelTrackPageView = () => {
  isMixpanelLoaded()?.track_pageview();
};

export const shouldMixPanelBeEnabled = () => {
  return process.env.NODE_ENV === 'production';
};
