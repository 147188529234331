import routeStrings from 'Routing/routeStrings';
import { ChecklistReviewFilter } from './CloseChecklist/components/ChecklistTablePanel/ChecklistTableHeader';

const { quality } = routeStrings.originator;

export class RouteUtils {
  static get homepageUrl() {
    return `/${quality.base}/:mortgageId`;
  }

  /**
   * @deprecated Use `RouteUtils.closeChecklistWithParams` instead
   */
  static get closeChecklist() {
    return `/${quality.base}/${quality.closeChecklist}`;
  }

  static get closeChecklistUrl() {
    return `/${quality.base}/${quality.closeChecklist}/:mortgageId/:filter`;
  }

  static closeChecklistWithParams(mortgageId: string, filter = ChecklistReviewFilter.Review) {
    return `/${quality.base}/${quality.closeChecklist}/${mortgageId}/${filter}`;
  }

  static get documentCategorizationReviewPureUrl() {
    return `/${quality.base}/:status/${quality.documentCategorization}/:mortgageId`;
  }

  static get documentCategorizationReviewUrl() {
    return `/${quality.base}/:status/${quality.documentCategorization}/:mortgageId/:documentId`;
  }

  static get documentCategorizationCategoryUrl() {
    return `/${quality.base}/:status/${quality.documentCategorization}/:mortgageId/category/:category`;
  }

  static get loanQualityAdvisorUrl() {
    return `/${quality.base}/${quality.lqa}/:mortgageId`;
  }
}
